.back-stroke {
  stroke-width: 6px;
  stroke: #ffffffcc;
  fill: none;
}

.fore-stroke {
  stroke-width: 4px;
  stroke: #000000cc;
  fill: none;
}
